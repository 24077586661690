import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: '#2C1E4A',
      },
      header: {
        color: '#2C1E4A',
      },
      footer: {
        color: '#2C1E4A',
      },
    },
  },
  fonts: {
    body: "'Montserrat', sans-serif",
  },
  components: {
    Text: {
      baseStyle: {
        color: '#ffffff',
        fontFamily: "'Montserrat', sans-serif",
      },
    },
    Button: {
      baseStyle: {
        color: '#ffffff',
        fontFamily: "'Montserrat', sans-serif",
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          _checked: {
            bg: '#2C1E4A',
          },
        },
      },
    },
  },
});
