import React from 'react';
import { AspectRatio, Box, Flex } from '@chakra-ui/react';

import { ReactComponent as DedupLogo } from '../resources/images/dedupLogo.svg';
import { HEADERS } from '../resources/constants';
import CoreSlide from './core/CoreSlide';
import CoreParagraph from './core/CoreParagraph';
import CoreButton from './core/CoreButton';

const paragraphs = {
  mainParagraph:
    'Our innovative tool integrates seamlessly with your existing SharePoint system, offering an intuitive interface that makes it simple for users to identify and eliminate redundant files. With DeDup, you can ensure that every document and piece of data in your digital workspace serves a purpose, eliminating unnecessary duplicates and freeing up valuable storage space.',
};

const Products = () => {
  return (
    <div className="section" id="products">
      <CoreSlide category={HEADERS.products}>
        <Flex
          flexDir="column"
          justify="space-evenly"
          w={{
            base: '95%',
            sm: '95%',
            md: '90%',
            lg: '90%',
            xl: '90%',
            '2xl': '90%',
          }}
        >
          <Box
            display="flex"
            flexDirection={{
              base: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
              xl: 'row',
              '2xl': 'row',
            }}
            alignItems="center"
            gap="50px"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              w="100%"
              h="100%"
            >
              <Box
                width={{
                  base: '250px',
                  sm: '350px',
                  md: '350px',
                  lg: '400px',
                  xl: '400px',
                  '2xl': '400px',
                }}
                height={{
                  base: '130px',
                  sm: '170px',
                  md: '170px',
                  lg: '200px',
                  xl: '200px',
                  '2xl': '200px',
                }}
              >
                <DedupLogo
                  alt="dedup-logo"
                  width={{
                    base: '250px',
                    sm: '350px',
                    md: '350px',
                    lg: '400px',
                    xl: '400px',
                    '2xl': '400px',
                  }}
                  height={{
                    base: '130px',
                    sm: '170px',
                    md: '170px',
                    lg: '200px',
                    xl: '200px',
                    '2xl': '200px',
                  }}
                />
              </Box>

              <CoreParagraph text={paragraphs.mainParagraph} />

              <CoreButton
                text="TRY IT NOW!"
                onClick={() =>
                  window.open(
                    'https://azuremarketplace.microsoft.com/en-us/marketplace/apps/nonlinearsrl1585145696815.sharepoint_deduplicator?tab=Overview',
                    '_blank'
                  )
                }
                w={{
                  base: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '300px',
                  xl: '300px',
                  '2xl': '300px',
                }}
                mt="10"
                height={{
                  base: '50px',
                  sm: '50px',
                  md: '90px',
                  lg: '90px',
                  xl: '90px',
                  '2xl': '90px',
                }}
                border="4px solid white"
                borderRadius="0"
                bg="transparent"
                color="white"
              />
            </Box>

            <Box style={{ width: '100%', height: '100%' }}>
              <AspectRatio maxW={'100%'} ratio={16 / 9}>
                <iframe
                  style={{ width: '100%', height: '100%' }}
                  src="https://www.youtube.com/embed/xMtch8Y1ryg?autoplay=1&mute=1"
                  title="YouTube video player"
                  allowFullScreen
                ></iframe>
              </AspectRatio>
            </Box>
          </Box>
        </Flex>
      </CoreSlide>
    </div>
  );
};

export default Products;
