import React from 'react';
import { Box, Container } from '@chakra-ui/react';

import CoreSubtitle from './core/CoreSubtitle';
import CoreParagraph from './core/CoreParagraph';
import CoreTitle from './core/CoreTitle';

const data = [
  {
    subtitle: 'Effective date: January 2020',
    newLine: false,
    paragraph:
      'We know you care about how your personal information is used and shared, and we take your privacy seriously. Please read the following to learn more about our Privacy Policy. By using or accessing the Services in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, and share your information in the following ways.',
  },
  {
    subtitle: 'What does this Privacy Policy cover?',
    newLine: false,
    paragraph:
      'This Privacy Policy covers our treatment of personally identifiable information ("Personal Information") that we gather when you are accessing or using our Services, but not to the practices of companies we don’t own or control, or people that we don’t manage. We gather various types of Personal Information from our users, as explained in more detail below, and we use this Personal Information internally in connection with our Services, including to personalize, provide, and improve our services, to allow you to set up a user account and profile, to contact you and allow other users to contact you, to fulfill your requests for certain products and services, and to analyze how you use the Services. In certain cases, we may also share some Personal Information with third parties, but only as described below.',
  },
  {
    subtitle: 'Will Nonlinear change this Privacy Policy?',
    newLine: false,
    paragraph:
      'We’re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time as well, but we will alert you to changes by placing a notice on your email and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us (or you haven’t provided us with your email address), those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes. Use of information we collect now is subject to the Privacy Policy in effect at the time such information is collected.',
  },
  {
    subtitle: 'What Information does Nonlinear Collect?',
    newLine: true,
    paragraphs: [
      'Information You Provide to Us:',
      'We receive and store any information you knowingly provide to us. For example, through the registration process, services and/or through your account settings, we may collect Personal Information such as your name, company name, postal address, telephone number, email address, phone number. If you provide your third-party account information to us [or otherwise sign in to the Services through a third-party site or service, you understand some content and/or information in those accounts (“Third Party Account Information”) may be transmitted into your account with us if you authorize such transmissions, and that Third-Party Account Information transmitted to our Services is covered by this Privacy Policy. Certain information may be required to register with us or to take advantage of some of our features. We may communicate with you if you’ve provided us the means to do so. For example, if you’ve given us your email address, we may send you promotional email offers on behalf of other businesses, or email you about your use of the Services.',
    ],
  },
  {
    subtitle: 'Information Collected Automatically',
    newLine: true,
    paragraphs: [
      'Whenever you interact with our Services, we automatically receive and record information on our server logs from your browser or our device, which may include your IP address, geolocation data, device identification, “cookie” information, the type of browser and/or device you’re using to access our Services, the page or feature you requested, the conversation you had with Robots, the movement you made in front of Robots and other information recorded by Robots automatically. “Cookies” are identifiers we transfer to your browser or device that allow us to recognize your browser or device and tell us how and when pages and features in our Services are visited and by how many people. You may be able to change the preferences on your browser or device to prevent or limit your device’s acceptance of cookies, but this may prevent you from taking advantage of some of our features.',
      'If you click on a link to a third party website or service, a third party may also transmit cookies to you. Again, this Privacy Policy does not cover the use of cookies by any third parties, and we aren’t responsible for their privacy policies and practices. Please be aware that cookies placed by third parties may continue to track your activities online even after you have left our Services, and those third parties may not honor “Do Not Track” requests you have set using your browser or our device/Robots.',
      'We may use this data to customize content for you that we think you might like, based on your usage patterns. We may also use it to improve the Services – for example, this data can tell us how often users use a particular feature of the Services, and we can use that knowledge to make the Services interesting to as many users as possible.',
    ],
  },
  {
    subtitle:
      'Will Nonlinear Share Any of the Personal Information it Receives?',
    newLine: true,
    paragraphs: [
      'We do not rent or sell your Personal Information in personally identifiable form to anyone, except as expressly provided below. We may share your Personal Information with third parties as described in this section:',
      'Information that’s been de-identified. We may de-identify your Personal Information so that you are not identified as an individual, and provide that information to our partners. We may also provide aggregate usage information to our partners (or allow partners to collect that information from you), who may use such information to understand how often and in what ways people use our Services, so that they, too, can provide you with an optimal experience. However, we never disclose aggregate usage or de-identified information to a partner (or allow a partner to collect such information) in a manner that would identify you as an individual person.',
      'Affiliated Businesses: In certain situations, businesses or third-party websites we’re affiliated with may sell or provide products or services to you through or in connection with the Services (either alone or jointly with us). You can recognize when an affiliated business is associated with such a transaction or service, and we will share your Personal Information with that affiliated business only to the extent that it is related to such transaction or service. We have no control over the policies and practices of third-party websites or businesses as to privacy or anything else, so if you choose to take part in any transaction or service relating to an affiliated website or business, please review all such business’ or websites’ policies.',
      'Agents: We employ other companies and people to perform tasks on our behalf and need to share your information with them to provide products or services to you; for example, we may use a payment processing company to receive and process your credit card transactions for us. Unless we tell you differently, our agents do not have any right to use the Personal Information we share with them beyond what is necessary to assist us. Note that an “agent” may also be considered a “partner” in certain circumstances, and would be subject to the terms of the “Information that’s been de-identified” section in that regard.',
      'User Profiles and Submissions: Certain user profile information, including your name, location, and any video or image content that such user has uploaded to the Services, may be used to facilitate user interaction within the Services or address your request for our services. Please remember that any content you upload to your public user profile, along with any Personal Information or content that you voluntarily disclose online in a manner other user can view (on discussion boards, in messages and chat areas, etc.) becomes publicly available, and can be collected and used by anyone. Your user name may also be displayed to other users if and when you send messages or comments or upload images or videos through the Services and other users can contact you through messages and comments.',
      'Business Transfers: We may choose to buy or sell assets, and may share and/or transfer customer information in connection with the evaluation of and entry into such transactions. Also, if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, Personal Information could be one of the assets transferred to or acquired by a third party.',
      'Protection of Nonlinear and Others: We reserve the right to access, read, preserve, and disclose any information that we reasonably believe is necessary to comply with law or court order or protect the rights, property, or safety of Company, our employees, our users, or others.',
    ],
  },
  {
    subtitle: 'Is Personal Information about me secure?',
    newLine: true,
    paragraphs: [
      'We use cloud service providers to host the information we collect, and we use technical measures to secure your data.',
      'We endeavor to protect the privacy of your account and other Personal Information we hold in our records, and we strive to implement and maintain reasonable, commercially acceptable security procedures and practices appropriate to the nature of the information we store, in order to protect it from unauthorized access, destruction, use, modification, or disclosure.',
      'However, please be aware that no method of transmission over the internet, or method of electronic storage is 100% secure and we are unable to guarantee the absolute security of the Personal Information we have collected from you.',
    ],
  },
  {
    subtitle: 'What Personal Information can I access?',
    newLine: true,
    paragraphs: [
      'Through your account settings, you may access, and, in some cases, edit or delete the following information you’ve provided to us:',
      '1. name and password',
      '2. email address',
      '3. location',
      '4. user profile information, including images and videos you have uploaded to the site',
      'The information you can view, update, and delete may change as the Services change. If you have any questions about viewing or updating information we have on file about you, please contact us at contact@nonlinear.ro',
    ],
  },
  {
    subtitle: 'What choices do I have?',
    newLine: true,
    paragraphs: [
      'You can always opt not to disclose information to us, but keep in mind some information may be needed to register with us or to take advantage of some of our features.',
      'You may be able to add, update, or delete information as explained above. When you update information, however, we may maintain a copy of the unrevised information in our records. [You may request deletion of your account by contact@nonlinear.ro.',
      'Some information may remain in our records after your deletion of such information you’re your account. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally.',
    ],
  },
  {
    subtitle: 'What if I have questions about this policy?',
    newLine: false,
    paragraph:
      'If you have any questions or concerns regarding our privacy policies, please send us a detailed message to contact@nonlinear.ro and we will try to resolve your concerns.',
  },
];

const PolicyAndTermsAndConditions = () => {
  return (
    <Container
      maxWidth={{
        base: '85%',
        sm: '85%',
        md: '85%',
        lg: '75%',
        xl: '70%',
        '2xl': '70%',
      }}
    >
      <CoreTitle text=" Nonlinear Privacy Policy" />

      {data.map((item, index) => (
        <div key={index}>
          <Box textAlign="left" fontWeight="bold">
            <CoreSubtitle text={item.subtitle} />
          </Box>
          {item.newLine ? (
            <>
              {item.paragraphs.map((paragraph, indexP) => (
                <div key={indexP}>
                  <CoreParagraph text={paragraph} />
                  <br />
                </div>
              ))}
            </>
          ) : (
            <>
              <CoreParagraph text={item.paragraph} />
              <br />
            </>
          )}
        </div>
      ))}
    </Container>
  );
};

export default PolicyAndTermsAndConditions;
