import React, { useMemo, useState } from 'react';
import {
  Box,
  Text,
  Flex,
  Textarea,
  Input,
  Checkbox,
  useToast,
} from '@chakra-ui/react';

import { useNotification } from '../hooks/notification';

import { HEADERS } from '../resources/constants';
import COLORS from '../resources/app-data/colors';
import CoreTitle from './core/CoreTitle';
import CoreSlide from './core/CoreSlide';
import CoreButton from './core/CoreButton';
import ApiHelper from '../helpers/api-helper';

const INPUT_STYLE = {
  size: 'lg',
  bg: '#ffffff',
  w: '100%',
  mr: '2',
  margin: '10px 0',
};

const INITIAL_FORM = {
  name: '',
  email: '',
  phone: '',
  message: '',
};

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const GetInTouch = () => {
  const notification = useNotification(useToast());
  const [form, setForm] = useState(INITIAL_FORM);
  const [operationInProgress, setOperationInProgress] = useState(false);
  const [isSubmitChecked, setIsSubmitChecked] = useState(false);

  const onFormUpdate = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onSubmitForm = () => {
    setOperationInProgress(true);

    ApiHelper.sendYourRequest(form)
      .then(() => {
        notification.success(
          'Thank you for your interest!',
          'We will contact you as soon as possible!'
        );
        setForm(INITIAL_FORM);
        setIsSubmitChecked(false);
      })
      .catch(err => {
        notification.error(
          'Your message could not be sent!',
          'Please try again.'
        );
      })
      .finally(() => setOperationInProgress(false));
  };

  const isEmailInvalid =
    form.email?.trim() !== '' && !EMAIL_REGEX.test(form.email?.trim());
  const isPhoneInvalid =
    form.phone && (!form.phone?.startsWith('07') || form.phone?.length !== 10);

  const isFormValid =
    form.name?.trim() !== '' &&
    form.email?.trim() !== '' &&
    !isEmailInvalid &&
    form.phone &&
    !isPhoneInvalid &&
    form.message?.trim() !== '' &&
    isSubmitChecked;

  const formFields = useMemo(
    () => [
      {
        name: 'name',
        type: 'string',
        placeholder: 'Name *',
        isDisabled: operationInProgress,
        value: form.name,
      },
      {
        name: 'email',
        type: 'string',
        placeholder: 'Email address *',
        isDisabled: operationInProgress,
        value: form.email,
        isInvalid: isEmailInvalid,
        errorMessage: 'Please insert a valid email!',
      },
      {
        name: 'phone',
        type: 'number',
        placeholder: 'Phone number *',
        isDisabled: operationInProgress,
        value: form.phone,
        isInvalid: form.phone !== '' && isPhoneInvalid,
        errorMessage: ' Please insert a valid phone!',
      },
    ],
    [
      form.email,
      form.name,
      form.phone,
      isEmailInvalid,
      isPhoneInvalid,
      operationInProgress,
    ]
  );

  return (
    <div className="section" id="get-in-touch">
      <CoreSlide category={HEADERS.getInTouch}>
        <Flex flexDir="column" justify="space-evenly" h="100%" mt="12">
          <Box mb="6">
            <CoreTitle text="GET IN TOUCH" />
          </Box>

          <Box mt="10">
            <Flex
              justifyContent="space-between"
              direction={{
                base: 'column',
                sm: 'column',
                md: 'column',
                lg: 'row',
                xl: 'row',
                '2xl': 'row',
              }}
              gap={{
                base: '0',
                sm: '0',
                md: '0',
                lg: '40px',
                xl: '40px',
                '2xl': '40px',
              }}
            >
              <Flex
                justifyContent="center"
                width="100%"
                direction="column"
                overflow="auto"
                minWidth={{
                  base: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '450px',
                  xl: '550px',
                  '2xl': '550px',
                }}
              >
                {formFields.map((field, index) => (
                  <div key={`${index}-${field.name}`}>
                    <Input
                      name={field.name}
                      placeholder={field.placeholder}
                      isDisabled={field.isDisabled}
                      isInvalid={field.isInvalid}
                      value={field.value}
                      type={field.type}
                      onChange={onFormUpdate}
                      {...INPUT_STYLE}
                    />

                    {field.isInvalid && (
                      <Text fontSize="sm" color="red.600" fontStyle="italic">
                        {field.errorMessage}
                      </Text>
                    )}
                  </div>
                ))}
              </Flex>

              <Flex
                direction="column"
                alignItems="flex-start"
                width="100%"
                mt="4"
                mb="4"
              >
                <Textarea
                  name="message"
                  placeholder="Your message here *"
                  width="100%"
                  minWidth={{
                    base: '100%',
                    sm: '100%',
                    md: '100%',
                    lg: '450px',
                    xl: '550px',
                    '2xl': '550px',
                  }}
                  maxHeight="400px"
                  height="200px"
                  bg="#ffffff"
                  value={form.message}
                  onChange={onFormUpdate}
                  disabled={operationInProgress}
                />
              </Flex>
            </Flex>

            <Flex
              direction="row"
              alignItems="center"
              justifyContent="center"
              mt="5"
              mb="4"
            >
              <Checkbox
                isChecked={isSubmitChecked}
                isDisabled={operationInProgress}
                onChange={() => setIsSubmitChecked(!isSubmitChecked)}
              >
                <Text fontSize="sm" ml="2">
                  By submitting you agree to our Terms & Conditions & Privacy
                  Policy.
                </Text>
              </Checkbox>
            </Flex>

            <CoreButton
              isDisabled={operationInProgress || !isFormValid}
              isLoading={operationInProgress}
              onClick={onSubmitForm}
              text="Send Your Request"
              borderRadius="50px"
              bg={COLORS.submit}
              color="white"
            />
          </Box>
        </Flex>
      </CoreSlide>
    </div>
  );
};

export default GetInTouch;
