import React, { useContext } from 'react';
import { Flex, Button, Box, Container } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as NonlinearLogo } from '../resources/images/nonlinearLogo.svg';
import SectionsContext from '../resources/SectionsContext';
import { HEADERS } from '../resources/constants';

const BUTTON_STYLE = {
  display: {
    base: 'none',
    sm: 'none',
    md: 'block',
    lg: 'block',
    xl: 'block',
    '2xl': 'block',
  },
  ml: '4',
  mr: '4',
  color: '#ffffff',
  fontSize: {
    base: 'none',
    sm: 'none',
    md: '16px',
    lg: '16px',
    xl: '16px',
    '2xl': '16px',
  },
  fontWeight: 'bold',
  variant: 'link',
  _focus: { boxShadow: 'none' },
};

const Header = () => {
  const { scrollTo } = useContext(SectionsContext);

  const handleClick = key => {
    scrollTo(key);
  };

  return (
    <Flex
      width="100%"
      justifyContent="center"
      position="sticky"
      top="0"
      zIndex="50"
    >
      <Container
        maxWidth="100%"
        zIndex="1"
        display="flex"
        direction="row"
        alignItems="center"
        pt="0"
        pb="0"
        height="80px"
        justifyContent="space-between"
        position="absolute"
        bg="rgb(44, 30, 74, 0.8)"
      >
        <Box height="60px" mt="-7" ml="-5">
          <NonlinearLogo
            id="headerLogo"
            alt="nonlinear-logo"
            height="90px"
            width="150px"
            style={{ transition: 'opacity 0.3s ease-in-out' }}
          />
        </Box>

        <Flex
          gap={{
            base: 'none',
            sm: 'none',
            md: '20px',
            lg: '50px',
            xl: '50px',
            '2xl': '50px',
          }}
        >
          <Button
            onClick={() => handleClick(HEADERS.aboutUs)}
            {...BUTTON_STYLE}
          >
            <NavLink to="#about-us">{HEADERS.aboutUs}</NavLink>
          </Button>

          <Button onClick={() => handleClick(HEADERS.whyUs)} {...BUTTON_STYLE}>
            <NavLink to="#why-us">{HEADERS.whyUs}</NavLink>
          </Button>

          <Button
            onClick={() => handleClick(HEADERS.products)}
            {...BUTTON_STYLE}
          >
            <NavLink to="#products">{HEADERS.products}</NavLink>
          </Button>

          <Button
            onClick={() => handleClick(HEADERS.getInTouch)}
            {...BUTTON_STYLE}
          >
            <NavLink to="#get-in-touch">{HEADERS.getInTouch}</NavLink>
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
};

export default Header;
