import {
  Flex,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';

import CONTACT_INFO from '../resources/app-data/main';
import { ReactComponent as PhoneIcon } from '../resources/images/telephone.svg';
import { ReactComponent as EmailIcon } from '../resources/images/email.svg';
import COLORS from '../resources/app-data/colors';
import PolicyAndTermsAndConditions from '../components/policy-and-terms-and-conditions';

const FooterContent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Fade bottom>
      <Flex
        width="100%"
        height="200px"
        justifyContent="center"
        alignItems="center"
        backgroundColor={COLORS.defaultColor}
      >
        <Flex
          direction={{
            base: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
            xl: 'row',
            '2xl': 'row',
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Flex direction="column" alignItems="center">
            <Text>CONTACT US</Text>

            <Flex direction="row" gap="10px" alignItems="center">
              <PhoneIcon
                style={{ height: '18px', width: '18px', marginTop: '8px' }}
              />
              <Text fontSize="md" mt="2">
                <a href={'tel:' + CONTACT_INFO.phone} className="footerContent">
                  {CONTACT_INFO.phone}
                </a>
              </Text>
            </Flex>

            <Flex
              direction="row"
              gap="10px"
              alignItems="center"
              cursor="pointer"
            >
              <EmailIcon
                style={{ height: '18px', width: '18px', marginTop: '8px' }}
              />
              <Text fontSize="md" mt="2">
                <a
                  href={'mailTo:' + CONTACT_INFO.email}
                  className="footerContent"
                >
                  {CONTACT_INFO.email}
                </a>
              </Text>
            </Flex>
          </Flex>

          <Flex
            direction="column"
            alignItems="center"
            ml={{
              base: '0',
              sm: '0',
              md: '60',
              lg: '60',
              xl: '60',
              '2xl': '60',
            }}
          >
            <Text
              fontSize="md"
              mt="6"
              onClick={() => setIsModalOpen(true)}
              className="footerContent"
            >
              Policy
              <br />
              Terms & Conditions
            </Text>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <ModalContent
                bg={COLORS.defaultColor}
                maxWidth="100%"
                marginTop="0"
                marginBottom="0"
              >
                <ModalCloseButton color="white" />
                <ModalBody>
                  <PolicyAndTermsAndConditions />
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>
        </Flex>
      </Flex>
    </Fade>
  );
};

export default FooterContent;
