import React from 'react';

import Home from '../components/home';
import AboutUs from '../components/about-us';
import WhyUs from '../components/why-us';
import Products from '../components/products';
import GetInTouch from '../components/get-in-touch';
import { HEADERS } from '../resources/constants';

const COMPONENTS = {
  [HEADERS.home]: Home,
  [HEADERS.aboutUs]: AboutUs,
  [HEADERS.whyUs]: WhyUs,
  [HEADERS.products]: Products,
  [HEADERS.getInTouch]: GetInTouch,
};

const HomePage = () => {
  return (
    <>
      {Object.keys(COMPONENTS).map((key, index) => {
        const Section = COMPONENTS[key];
        return <Section key={`${key}-${index}`} />;
      })}
    </>
  );
};

export default HomePage;
