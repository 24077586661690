import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { HEADERS } from '../resources/constants';
import CoreTitle from './core/CoreTitle';
import CoreSlide from './core/CoreSlide';
import CoreParagraph from './core/CoreParagraph';

const paragraphs = {
  mainParagraph:
    'Nonlinear is a software company based in Romania, a subsidiary of Nenos Software, that has been a member of the Bittnet Group since 2021. Our company offers deep neural network technology products and solutions to organizations seeking to optimize and automate document-centric processes and accelerate business transformation.',
};

const AboutUs = () => {
  return (
    <div className="section" id="about-us">
      <CoreSlide category={HEADERS.aboutUs}>
        <Flex
          flexDir="column"
          h="100%"
          w={{
            base: '100%',
            sm: '90%',
            md: '75%',
            lg: '65%',
            xl: '65%',
            '2xl': '65%',
          }}
        >
          <Box mb="12">
            <CoreTitle text="ABOUT NONLINEAR" />
          </Box>

          <CoreParagraph text={paragraphs.mainParagraph} />
        </Flex>
      </CoreSlide>
    </div>
  );
};

export default AboutUs;
