import React from 'react';

import { Button } from '@chakra-ui/react';

const CoreButton = ({
  variant,
  text,
  isDisabled,
  isLoading,
  onClick,
  ...styleProps
}) => {
  return (
    <Button
      variant={variant}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={onClick}
      _hover={{
        opacity: 0.7,
      }}
      _disabled={{
        opacity: 0.7,
        cursor: 'not-allowed',
      }}
      {...(styleProps || {})}
    >
      {text}
    </Button>
  );
};

export default CoreButton;
