export default class ApiHelper {
  static sendYourRequest(form = null) {
    return fetch(process.env.REACT_APP_BACKEND_URL, {
      method: 'post',
      body: JSON.stringify(form),
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods':
          'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers':
          'Origin, Content-Type, X-Auth-Token, Authorization, Lang',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('API call failed');
        }
        return response.json();
      })
      .catch(error => {
        throw new Error(error);
      });
  }
}
