import React from 'react';

import { Text } from '@chakra-ui/react';

const CoreParagraph = ({ text }) => {
  return (
    <Text
      fontSize={{
        base: '16px',
        sm: '16px',
        md: '16px',
        lg: '16px',
        xl: '16px',
        '2xl': '16px',
      }}
      align="justify"
    >
      {text}
    </Text>
  );
};

export default CoreParagraph;
