import React from 'react';
import { Box, Flex, Grid, Image } from '@chakra-ui/react';

import RampupImage from '../resources/images/rampUp.svg';
import CommunicationImage from '../resources/images/communication.svg';
import ReabilityImage from '../resources/images/reability.svg';
import LowPriceImage from '../resources/images/lowPrice.svg';
import TeamValueImage from '../resources/images/teamValue.svg';
import { HEADERS } from '../resources/constants';
import CoreTitle from './core/CoreTitle';
import CoreSlide from './core/CoreSlide';
import CoreSubtitle from './core/CoreSubtitle';
import CoreParagraph from './core/CoreParagraph';

const leftSide = [
  {
    subtitle: 'QUICK TEAM RAMP-UP',
    image: RampupImage,
    paragraph:
      'We are well-versed in a variety of project management and collaboration tools. We have the ability to smoothly and quickly adapt to those used by your company.',
  },
  {
    subtitle: 'SMOOTH COMMUNICATION',
    image: CommunicationImage,
    paragraph:
      'Our specialists have proven experience in their functional areas and can convey complex concepts clearly to all stakeholders.',
  },
  {
    subtitle: 'RELIABILITY & FLEXIBILITY',
    image: ReabilityImage,
    paragraph:
      'You can scale the team up or down within a matter of weeks. We can start to build initial teams, fill positions for specific projects, and expand team capabilities during “crunch-times”.',
  },
];

const rightSide = [
  {
    subtitle: 'COST-EFFECTIVE PRICING',
    image: LowPriceImage,
    paragraph:
      'We give you great value for access to our talents. Our pricing is straight-forward — apply various models: hourly, part-, or full-time for a fixed price on a weekly basis. No hidden fees.',
  },
  {
    subtitle: 'TEAM VALUE',
    image: TeamValueImage,
    paragraph:
      'Company value — especially for start-ups — is largely impacted by your ability to build an amazing team and product. We can provide in-house teams with more bandwidth or to extend the team’s capabilities, they enable your company to build your team and product faster',
  },
];

const WhyUs = () => {
  return (
    <div className="section" id="why-us">
      <CoreSlide category={HEADERS.whyUs}>
        <Flex
          flexDir="column"
          justify="space-evenly"
          h="100%"
          maxWidth={{
            base: '100%',
            sm: '100%',
            md: '100%',
            lg: '95%',
            xl: '95%',
            '2xl': '95%',
          }}
        >
          <Box mt="16">
            <CoreTitle text="WHY US?" />
          </Box>

          <Box
            display="flex"
            flexDirection={{
              base: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
              xl: 'row',
              '2xl': 'row',
            }}
            gap={{
              base: '0',
              sm: '0',
              md: '50px',
              lg: '50px',
              xl: '100px',
              '2xl': '100px',
            }}
          >
            <Grid
              templateColumns={{
                base: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(2, 1fr)',
                xl: 'repeat(2, 1fr)',
                '2xl': 'repeat(2, 1fr)',
              }}
              gap={{
                base: 0,
                sm: 0,
                md: 10,
                lg: 10,
                xl: 10,
                '2xl': 10,
              }}
            >
              <Grid gap={12} marginTop="10">
                {leftSide.map((item, index) => (
                  <Box
                    key={index}
                    mt="12"
                    display="flex"
                    flexDirection="row"
                    marginTop="0"
                  >
                    <Image
                      src={item.image}
                      alt="logo"
                      width={{
                        base: '30px',
                        sm: '30px',
                        md: '30px',
                        lg: '50px',
                        xl: '50px',
                        '2xl': '50px',
                      }}
                      height={{
                        base: '30px',
                        sm: '30px',
                        md: '30px',
                        lg: '50px',
                        xl: '50px',
                        '2xl': '50px',
                      }}
                    />

                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      ml="15px"
                      mb={{
                        base: '-8',
                        sm: '-8',
                        md: '0',
                        lg: '0',
                        xl: '0',
                        '2xl': '0',
                      }}
                    >
                      <CoreSubtitle text={item.subtitle} />
                      <CoreParagraph text={item.paragraph} />
                    </Box>
                  </Box>
                ))}
              </Grid>
              <Grid gap={10} marginTop="12">
                {rightSide.map((item, index) => (
                  <Box
                    key={index}
                    mt="12"
                    display="flex"
                    flexDirection="row"
                    marginTop="0"
                  >
                    <Image
                      src={item.image}
                      alt="logo"
                      width={{
                        base: '30px',
                        sm: '30px',
                        md: '30px',
                        lg: '50px',
                        xl: '50px',
                        '2xl': '50px',
                      }}
                      height={{
                        base: '30px',
                        sm: '30px',
                        md: '30px',
                        lg: '50px',
                        xl: '50px',
                        '2xl': '50px',
                      }}
                    />

                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      ml="15px"
                      mb={{
                        base: '-8',
                        sm: '-8',
                        md: '0',
                        lg: '0',
                        xl: '0',
                        '2xl': '0',
                      }}
                    >
                      <CoreSubtitle text={item.subtitle} />
                      <CoreParagraph text={item.paragraph} />
                    </Box>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Flex>
      </CoreSlide>
    </div>
  );
};

export default WhyUs;
