import React, { useEffect, useRef } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { HEADERS } from '../resources/constants';
import { ReactComponent as NonlinearLogo } from '../resources/images/nonlinearLogo.svg';
import CoreSlide from './core/CoreSlide';
import CoreTitle from './core/CoreTitle';
import { useOnScreen } from '../hooks/onScreen';

const Home = () => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    const headerLogo = document.getElementById('headerLogo');

    if (isVisible) {
      headerLogo.style.opacity = 0;
    } else {
      headerLogo.style.opacity = 1;
    }
  }, [isVisible]);

  return (
    <div className="homeSection" ref={ref}>
      <CoreSlide category={HEADERS.home} bottom="0" width="100%">
        <Flex
          flexDir="column"
          justifyContent="flex-start"
          h="100%"
          width="80%"
          mt="100px"
        >
          <Box
            width={{
              base: '170px',
              sm: '250px',
              md: '300px',
              lg: '300px',
              xl: '300px',
              '2xl': '300px',
            }}
            height={{
              base: '120px',
              sm: '140px',
              md: '170px',
              lg: '170px',
              xl: '170px',
              '2xl': '170px',
            }}
            ml={{
              base: '-16px',
              sm: '-25px',
              md: '-30px',
              lg: '-30px',
              xl: '-30px',
              '2xl': '-30px',
            }}
          >
            <NonlinearLogo
              alt="nonlinear-logo"
              width={{
                base: '170px',
                sm: '250px',
                md: '300px',
                lg: '300px',
                xl: '300px',
                '2xl': '300px',
              }}
            />
          </Box>

          <Box
            textAlign="left"
            maxWidth={{
              base: '400px',
              sm: '700px',
              md: '1000px',
              lg: '1000px',
              xl: '1000px',
              '2xl': '1000px',
            }}
            mt="50px"
          >
            <CoreTitle text="MACHINE LEARNING AND SOFTWARE SOLUTIONS" />
          </Box>
        </Flex>
      </CoreSlide>
    </div>
  );
};

export default Home;
