import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import backgroundVideo from '../resources/background-videos/backgroundVideo.mp4';
import HomePage from './home-page';
import Header from './header';
import ROUTES from '../resources/app-routes';
import PolicyAndTermsAndConditions from '../components/policy-and-terms-and-conditions';

const BodyContent = () => {
  const isHome = window.location.pathname === ROUTES.home;

  return (
    <>
      <BrowserRouter>
        {isHome && (
          <>
            <video autoPlay muted loop>
              <source src={backgroundVideo} type="video/mp4" />
            </video>

            <Header />
          </>
        )}

        <Switch>
          <Route exact path={ROUTES.home}>
            <HomePage />
          </Route>

          <Route exact path={ROUTES.privacyPolicy}>
            <PolicyAndTermsAndConditions />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default BodyContent;
