import React, { useContext, useEffect, useRef } from 'react';

import { Container } from '@chakra-ui/react';
import SectionsContext from '../../resources/SectionsContext';

const CoreSlide = ({ category, children }) => {
  const sectionRef = useRef(null);
  const { linkSectionRef } = useContext(SectionsContext);

  useEffect(() => {
    linkSectionRef(sectionRef, category);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      ref={sectionRef}
      bg="transparent"
      pt="36"
      pb="36"
      maxWidth="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Container>
  );
};

export default CoreSlide;
