import React from 'react';

import { Text } from '@chakra-ui/react';

const CoreSubtitle = ({ text }) => {
  return (
    <Text
      fontSize={{
        base: '15px',
        sm: '20px',
        md: '22px',
        lg: '27px',
        xl: '30px',
        '2xl': '30px',
      }}
      fontWeight="bold"
    >
      {text}
    </Text>
  );
};

export default CoreSubtitle;
