import React from 'react';

import { Text } from '@chakra-ui/react';

const CoreTitle = ({ text }) => {
  return (
    <Text
      fontSize={{
        base: '25px',
        sm: '40px',
        md: '50px',
        lg: '55px',
        xl: '55px',
        '2xl': '55px',
      }}
      fontWeight="bold"
    >
      {text}
    </Text>
  );
};

export default CoreTitle;
